import React from "react";
import LightGallery from "lightgallery/react";
import "./styles.css";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

const Fugenschneiden = () => {
	return (
		<LightGallery speed={500} plugins={[lgZoom, lgVideo]} mode="lg-fade">
			<a
				href
				className="gallery-item"
				data-src="img/fugenschneiden/fugenschneiden_b01.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/fugenschneiden/fugenschneiden_s01.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/fugenschneiden/fugenschneiden_b02.jpeg"
			>
				<img
					
					style={{ maxWidth: "480px", height: "340px" }}
					className="img-responsive"
					src="img/fugenschneiden/fugenschneiden_s02.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/fugenschneiden/fugenschneiden_b03.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/fugenschneiden/fugenschneiden_s03.jpeg"
				/>
			</a>
			
		</LightGallery>
	);
};

export default Fugenschneiden;
