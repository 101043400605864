import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollspyNav from "react-scrollspy-nav";

// sidebar content
const sidebarContent = [
	{ icon: "human", itemName: "Firma", itemRoute: "#about", activeClass: "" },

	{
		icon: "saw",
		itemName: "Betonsägen",
		itemRoute: "#betonsaegen",
		activeClass: "",
	},

	{
		icon: "saw",
		itemName: "Kernbohrung",
		itemRoute: "#kernbohrung",
		activeClass: "",
	},

	{
		icon: "saw",
		itemName: "Treppenöffnung",
		itemRoute: "#treppenoeffnung",
		activeClass: "",
	},

	{
		icon: "saw",
		itemName: "Wanddurchbruch",
		itemRoute: "#wanddurchbruch",
		activeClass: "",
	},

	{
		icon: "saw",
		itemName: "Balkonrückbau",
		itemRoute: "#balkonrueckbau",
		activeClass: "",
	},

	{
		icon: "saw",
		itemName: "Fugenschneiden",
		itemRoute: "#fugenschneiden",
		activeClass: "",
	},

	{
		icon: "contact",
		itemName: "Kontakt",
		itemRoute: "#contact",
		activeClass: "",
	},
];

// sidebar footer bottom content
const sidebarFooterContent = {
	impressum: "Impressum",
	impressumlink: "#impressum",
};

const Sidebar = () => {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	return (
		<>
			<div className="mob-header">
				<button className="toggler-menu" onClick={handleClick}>
					<div className={click ? "active" : ""}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</button>
			</div>
			{/* End Mobile Header */}
			<div
				className={click ? "edina_tm_sidebar menu-open" : "edina_tm_sidebar"}
			>
				<div className="sidebar_inner">
					<div className="logo">
						<img src="img/logo/dark2.png" width="90"></img>
					</div>
					{/* End .logo */}

					<div className="menu">
						<ScrollspyNav
							scrollTargetIds={[
								"about",

								"balkonrueckbau",
								"betonsaegen",
								"kernbohrung",
								"treppenoeffnung",
								"wanddurchbruch",

								"contact",
							]}
							activeNavClass="active"
							offset={0}
							scrollDuration="100"
						>
							<ul className="anchor_nav">
								{sidebarContent.map((val, i) => (
									<li key={i}>
										<div className="list_inner">
											<a
												href={val.itemRoute}
												className={val.activeClass}
												onClick={handleClick}
											>
												<img
													className="svg custom"
													src={`img/svg/${val.icon}.svg`}
													alt="icon"
												/>
												{val.itemName}
											</a>
										</div>
									</li>
								))}
							</ul>
						</ScrollspyNav>
					</div>
					{/* End .menu */}
					<div className="author">
						<div className="inner">
							<div className="short">
								<a href={sidebarFooterContent.impressumlink}>
									{sidebarFooterContent.impressum}
								</a>
							</div>
						</div>
					</div>

					{/* End author */}
				</div>
			</div>
		</>
	);
};

export default Sidebar;
