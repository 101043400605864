import React, { useEffect } from "react";
import CustomRouter from "./router/CustomRouter";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
//import AnimatedCursor from "react-animated-cursor";
import "aos/dist/aos.css";

const App = () => {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<>
			<ScrollToTop />
			<CustomRouter />
		</>
	);
};

export default App;
