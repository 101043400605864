import React from "react";
import LightGallery from "lightgallery/react";
import "./styles.css";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

const Wanddurchbruch = () => {
	return (
		<LightGallery speed={500} plugins={[lgZoom, lgVideo]} mode="lg-fade">
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b01.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s01.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b02.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s02.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b03.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s03.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b04.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s04.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b05.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s05.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b06.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s06.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b07.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s07.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b08.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s08.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b09.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s09.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/wanddurchbruch/wanddurchbruch_b10.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/wanddurchbruch/wanddurchbruch_s10.jpeg"
				/>
			</a>
		</LightGallery>
	);
};

export default Wanddurchbruch;
