import React from "react";

import LightGallery from "lightgallery/react";
import "./styles.css";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
const Betonsaegen = () => {
	return (
		<LightGallery speed={500} plugins={[lgZoom, lgVideo]} mode="lg-fade">
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b01.jpeg"
				
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s01.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b02.jpeg"
			>
				<img
					
					style={{ maxWidth: "480px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s02.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b03.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s03.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b04.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s04.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b06.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s06.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b07.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s07.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b08.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s08.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b09.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s09.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b10.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s10.jpeg"
				/>
			</a>{" "}
			
			<a
				href
				className="gallery-item"
				data-video='{"source": [{"src":"img/betonsaegen/betonsaegen_b12.mp4", "type":"video/mp4"}],  "attributes": {"preload": false, "playsinline": true, "controls": true, "muted": true }}'
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s12.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-video='{"source": [{"src":"img/betonsaegen/betonsaegen_b13.mp4", "type":"video/mp4"}],  "attributes": {"preload": false, "playsinline": true, "controls": true, "muted": true }}'
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s13.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b14.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s14.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b15.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s15.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b16.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s16.jpeg"
				/>
			</a>{" "}
			
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b18.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s18.jpeg"
				/>
			</a>{" "}
			
			<a
				href
				className="gallery-item"
				data-src="img/betonsaegen/betonsaegen_b20.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/betonsaegen/betonsaegen_s20.jpeg"
				/>
			</a>{" "}
		</LightGallery>
	);
};

export default Betonsaegen;
