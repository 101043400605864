import React from "react";
//import Social from "../Social";

const heroContent = {
	shapeImage: "hero-shape",
	heroImage: "2",
	name: "",
	description: `Betonsägen / Kernbohrungen / Fugenschneiden /
  Balkonrückbau / Treppenöffnungen / Wanddurchbrüche`,
};

const Hero = () => {
	return (
		//    HERO
		<div className="edina_tm_hero" id="home">
			<div className="content">
				<div className="logo" data-aos="fade-up" data-aos-duration="1200">
					<img src={`img/hero/${heroContent.heroImage}.png`} alt="hero" />
				</div>
				<div className="extra">
					<h1
						className="name"
						data-aos="fade-up"
						data-aos-duration="1200"
						data-aos-delay="100"
					>
						{heroContent.name}
					</h1>
					<p
						className="text"
						data-aos="fade-up"
						data-aos-duration="1200"
						data-aos-delay="200"
					>
						{heroContent.description}
					</p>
				</div>
			</div>
		</div>
		// /HERO
	);
};

export default Hero;
