import React from "react";
import HomeDark from "../views/HomeDark";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
//import Impressum from "../components/Impressum";
//import Datenschutz from "../components/Datenschutz";

const CustomRouter = () => {
	return (
		<>
			<Router>
				<ScrollTopBehaviour />
				<Routes>
					<Route path="/" index element={<HomeDark />} />
					<Route element={<NotFound />} />
				</Routes>
			</Router>
		</>
	);
};

export default CustomRouter;
