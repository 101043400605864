import React from "react";
import Sidebar from "../components/header/Sidebar";

import Hero from "../components/slider/Hero";
import About from "../components/about/About";
import Betonsaegen from "../components/portfolio/Betonsaegen";
import Balkonrueckbau from "../components/portfolio/Balkonrueckbau";
//import Fugenschneiden from "../../components/portfolio/Fugenschneiden";
import Kernbohrung from "../components/portfolio/Kernbohrung";
import Treppenoeffnung from "../components/portfolio/Treppenoeffnung";
import Wanddurchbruch from "../components/portfolio/Wanddurchbruch";
import Fugenschneiden from "../components/portfolio/Fugenschneiden";
import Address from "../components/Address";

//import Map from "../components/Map";
//import AnimatedCursor from "react-animated-cursor";

const HomeDark = () => {
	document.body.classList.add("dark");
	return (
		<div className="home-light edina_tm_mainpart">
			<Sidebar />
			{/* End Header */}
			<Hero />
			{/* End Hero */}
			<About />
			{/* End Hero */}

			<div className="edina_tm_portfolio" id="betonsaegen">
				<div className="container">
					<div className="edina_tm_title">
						<h3>Betonsägen</h3>
						<p>
							Durch unsere elektrisch geführte Wandsäge sind Sägeschnitte bis zu
							1.000mm möglich. Baustoffe wie Stahlbeton, Mauerwerk,
							Buntsandstein, Yton und Granit können horizontal, vertikal, schräg
							sowie über Kopf gesägt werden. Beispiele für den Einsatzbereich
							von Wandsägen sind Tür-, Fenster- und Treppenöffnungen, das
							Herstellen von Galerien und Deckenöffnungen, das Abtrennen von
							Fundamenten und Absägen von Balkonen.
						</p>
					</div>
					{/* End edian_tm_title */}
					<Betonsaegen />
				</div>
			</div>
			{/* End Betonsaegen */}

			<div className="edina_tm_portfolio" id="kernbohrung">
				<div className="container">
					<div className="edina_tm_title">
						<h3>Kernbohrung</h3>
						<p>
							Wir führen Kernbohrungen von Ø25mm bis Ø1.000mm aus, wobei jede
							Bohrung auch schräg in verschiedenen Winkeln ausführbar ist. Durch
							das Nasstrennverfahren ist es uns möglich, Baustoffe wie Beton
							(auch stark armiert), Ziegelmauerwerk, Yton und Buntsandstein
							staubfrei zu bohren. Das Bohrwasser wird bei Bedarf durch einen
							Wassersammelring aufgefangen, wodurch nur minimale Verschmutzungen
							entstehen. Beispiele für Einsatzbereiche von Kernbohrungen sind
							Bohrungen für Luftkanäle, Elektro-, Heizungs- und
							Sanitärinstallationen, Hausanschlüsse und Entlastungsbohrungen.
						</p>
					</div>
					{/* End edian_tm_title */}
					<Kernbohrung />
				</div>
			</div>
			{/* End Kernbohrung */}

			<div
				className="edina_tm_treppenoeffnung edina_tm_portfolio"
				id="treppenoeffnung"
			>
				<div className="container">
					<div className="edina_tm_title">
						<h3>Treppenöffnung / Deckenaussparung</h3>
						<p>
							Mittels wandbündigem Sägen kann auch eine Treppenöffnung entstehen
							oder eine Galerie. Die schweren Betonteile werden anschließend
							auch in schwierigen Lagen mit einem Ladekran abtransportiert.
						</p>
					</div>
					{/* End edian_tm_title */}
					<Treppenoeffnung />
				</div>
			</div>
			{/* End Treppenoeffnung */}

			<div className="edina_tm_portfolio" id="wanddurchbruch">
				<div className="container">
					<div className="edina_tm_title">
						<h3>Wanddurchbruch</h3>
						<p>
							Auch das Durchtrennen einer Buntsandsteinwand bis 1 Meter Stärke
							ist möglich. Hierfür werden nacheinander größere Sägeblätter
							aufgespannt bis zum Erreichen der gewünschten Tiefe.
						</p>
					</div>
					{/* End edian_tm_title */}
					<Wanddurchbruch />
				</div>
			</div>
			{/* End Wanddurchbruch */}

			<div
				className="edina_tm_balkonrueckbau edina_tm_portfolio"
				id="balkonrueckbau"
			>
				<div className="container">
					<div className="edina_tm_title">
						<h3>Balkonrückbau</h3>
						<p>
							Das wandbündige Abtrennen des Balkons ist mit unserer elektrischen
							Wandsäge möglich. Die hierfür erforderlichen Maßnahmen wie das
							Demontieren und Entsorgen der Bauteile übernehmen wir fachgerecht.
						</p>
					</div>
					{/* End edian_tm_title */}
					<Balkonrueckbau />
				</div>
			</div>
			{/* End Balkonrueckbau */}

			<div
				className="edina_tm_balkonrueckbau edina_tm_portfolio"
				id="fugenschneiden"
			>
				<div className="container">
					<div className="edina_tm_title">
						<h3>Fugenschneiden</h3>
						<p>
							Da unsere Fugenschneider auch elektrisch angetrieben werden können, ist es uns möglich, 
							ohne Abgasentwicklung auch in Innenräumen zu sägen. Eine Schnitt-Tiefe von bis zu 50cm 
							ist hierbei zu erreichen. Präzise und optisch ansprechend sägen wir sichtbare Dehnungsfugen 
							in Beton, Stahlbeton, Asphalt und Frischbeton.
						</p>
					</div>
					{/* End edian_tm_title */}
					<Fugenschneiden />
				</div>
			</div>
			{/* End Fugenschneiden */}

			<div className="edina_tm_contact" id="contact">
				<div className="container">
					<div className="edina_tm_title">
						<h3>Kontakt</h3>
						<p></p>
					</div>
					<div className="extra_info">
						<Address />
					</div>
					{/* End Address Info */}
				</div>
			</div>
			{/* /CONTACT */}

			<div className="edina_tm_portfolio" id="impressum">
				<div className="container">
					<div className="edina_tm_title">
						<h4>Impressum</h4>
						<h5>Diensteanbieter</h5>
						<p>
							Michael Lucks<br></br>
							Rechtenbacher Str. 75<br></br>
							97816 Lohr am Main<br></br>
							Deutschland
						</p>

						<h5>Kontaktmöglichkeiten</h5>
						<p>
							<b>E-Mail-Adresse: </b>michael.lucks@gmx.de<br></br>
							<b>Telefon:</b> +49 171 7174 005
						</p>

						<h5>Vertretungsberechtigte</h5>
						<p>
							<b>Vertretungsberechtigt:</b> Michael Lucks
						</p>

						<h5>Journalistisch-redaktionelle Angebote</h5>
						<p>
							<b>Inhaltlich verantwortlich:</b> Michael Lucks (Adresse wie oben)
						</p>

						<h5>Angaben zum Unternehmen</h5>
						<p>
							<b>Umsatzsteuer Identifikationsnummer (USt-ID):</b> DE328173516
						</p>

						<h5>Haftungs- und Urheberrechtshinweise</h5>
						<p>
							<b>Haftungsausschluss:</b> Die Inhalte dieses Onlineangebotes
							wurden sorgfältig und nach unserem aktuellen Kenntnisstand
							erstellt, dienen jedoch nur der Information und entfalten keine
							rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich
							verpflichtende Informationen (z.B. das Impressum, die
							Datenschutzerklärung, AGB oder Widerrufsbelehrungen für
							Verbraucher) handelt. Wir behalten uns vor, die Inhalte
							vollständig oder teilweise zu ändern oder zu löschen, soweit
							vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind
							freibleibend und unverbindlich.
						</p>

						<h5>Bildnachweise</h5>
						<p>
							<b>Bildquellen und Urheberrechtshinweise:</b> <br></br>
							Michael Lucks<br></br>
						</p>
					</div>
				</div>
			</div>
			{/* /IMPRESSUM */}
		</div>
	);
};

export default HomeDark;
