import React from "react";
import LightGallery from "lightgallery/react";
import "./styles.css";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

const Kernbohrung = () => {
	return (
		<LightGallery speed={500} plugins={[lgZoom, lgVideo]} mode="lg-fade">
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b01.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s01.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b02.jpeg"
			>
				<img
					
					style={{ maxWidth: "480px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s02.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b03.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s03.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b04.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s04.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b05.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s05.jpeg"
				/>
			</a>
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b06.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s06.jpeg"
				/>
			</a>{" "}
			<a
				href
				className="gallery-item"
				data-src="img/kernbohrung/kernbohrung_b07.jpeg"
			>
				<img
					
					style={{ maxWidth: "340px", height: "340px" }}
					className="img-responsive"
					src="img/kernbohrung/kernbohrung_s07.jpeg"
				/>
			</a>
		</LightGallery>
	);
};

export default Kernbohrung;
